import { useEffect, useState } from "react";
import { isBrowserDefined } from "../services/browserService";

/**
 * Helper function to determine if a device should be considered as a small phone.
 * This determination is based on the width of the device's screen.
 *
 * @param {number} width - The width of the device's screen in pixels.
 * @returns {boolean} Returns true if the device's width is less than or equal to 296 pixels,
 * indicating it is a small phone. Otherwise, returns false.
 */
const checkIsSmall = (width: number): boolean => {
  return width <= 296;
};

/**
 * Custom hook for detecting if the current device is a small phone based on its screen width.
 * It leverages the `window.innerWidth` property to dynamically assess the size of the device
 * upon initialization and when the window is resized. This hook is useful for responsive designs
 * where different layouts or functionalities are needed for small phones.
 *
 * @returns {boolean} Returns a boolean indicating whether the current device is a small phone.
 * True means the device is a small phone, and false indicates otherwise.
 *
 * Example usage:
 * ```typescript
 * const isSmallPhone = useIsSmallPhone();
 * if (isSmallPhone) {
 *   // Render or execute logic for small phone devices
 * } else {
 *   // Render or execute logic for other devices
 * }
 * ```
 *
 * Note:
 * - This hook uses a state to store the current assessment of whether the device is a small phone.
 * - It includes an effect that attaches and cleans up a 'resize' event listener to the window object.
 *   This allows the hook to dynamically update its state in response to window resizing.
 * - The initial state is determined by checking if the browser environment is defined (to support SSR)
 *   and then assessing the screen width.
 */
const useIsSmallPhone = (): boolean => {
  /**
   * State to hold the current assessment of whether the device is considered a small phone.
   */
  const [isSmall, setIsSmall] = useState<boolean>(
    // On the server, `window` is undefined, so default to a safe value.
    isBrowserDefined() ? checkIsSmall(window.innerWidth) : false,
  );

  /**
   * Effect to handle window resize events and update the assessment of the device type accordingly.
   */
  useEffect(() => {
    const handleResize = () => {
      setIsSmall(checkIsSmall(window.innerWidth));
    };

    // Only attach the event listener if the window object is defined (i.e., in a browser environment).
    if (isBrowserDefined()) {
      window.addEventListener("resize", handleResize);
      // Cleanup function to remove the event listener.
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return isSmall;
};

export default useIsSmallPhone;
