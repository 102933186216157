import React from "react";
import strategyData from "../../../../static/strategyData";
import StrategyStep from "../../../../components/strategyStep";
import styles from "./strategyFlow.module.scss";
import { useTranslation } from "react-i18next";

/**
 * StrategyFlow component displays the strategy flow section on the Oasis page.
 * @returns {JSX.Element} The StrategyFlow component JSX
 */
const StrategyFlow: React.FC = (): JSX.Element => {
  /**
   * Retrieves translations for the component using useTranslation hook.
   */
  const { t } = useTranslation();
  return (
    <div className={"row justify-content-center"}>
      <div className={`${styles["strategy-container"]} col-md-6 col-sm-12`}>
        <div className={styles.strategyFlow}>
          {strategyData.map((step, index) => (
            <StrategyStep
              key={index}
              icon={step.image}
              title={t(step.title)}
              description={t(step.description)}
              position={step.position}
              dotColor={step.dotColor}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StrategyFlow;
