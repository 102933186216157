import React from "react";
import styles from "./coreSolutions.module.scss";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
const CoreSolutions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section
      id={"core-solutions"}
      className={`row ${styles["coreSolution-container"]} justify-content-end`}
    >
      <div
        className={`${styles["coreSolution-blurred-container"]} ${styles["custom-col-10"]} col-md-12 col-sm-12`}
      >
        <h2>{t("coreSolution.title")}</h2>
        <p>{t("coreSolution.description")}</p>
        <div className={styles["button-container"]}>
          <Button
            label={t("home.discoverOASIS")}
            onClick={() => navigate("/oasis")}
            buttonType={"dark"}
          />
        </div>
      </div>
    </section>
  );
};

export default CoreSolutions;
