import React from "react";
import DiscoverRight from "../../../../assets/images/shared/home/discover/discoverRight.svg";
import DiscoverLeft from "../../../../assets/images/shared/home/discover/discoverLeft.svg";
import styles from "./discover.module.scss";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/button";
import shadow from "../../../../assets/images/shared/home/discover/shadow.svg";
import useResponsiveImage from "../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../assets/images";
import { useNavigate } from "react-router-dom";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";

/**
 * Discover component displays information about discovering something.
 * @returns {JSX.Element} The Discover component JSX
 */
const Discover: React.FC<any> = ({ scrollPosition }): JSX.Element => {
  /**
   * Retrieves translations for the component using useTranslation hook.
   */
  const { t } = useTranslation();
  const discoverImage = useResponsiveImage(ImageKeys.homeDiscover);
  /**
   *  It returns a function 'navigate' that can be used to navigate to different routes programmatically.
   */
  const navigate = useNavigate();

  return (
    <div
      className={`row ${styles["discover-container"]} justify-content-lg-between`}
    >
      <div className={"p-0 col-lg-2 d-lg-flex d-md-none d-sm-none"}>
        <LazyLoadImage
          className={styles["sides"]}
          width={"auto"}
          height={"auto"}
          src={DiscoverLeft}
          alt={"discover right"}
        />
      </div>
      <div
        className={`col-lg-7 col-md-12 col-sm-12 p-0 ${styles["content-container"]}`}
      >
        <div
          className={`${styles["blur-background"]} col-md-6 col-sm-6 p-0`}
        ></div>
        <LazyLoadImage
          className={styles["main-image"]}
          src={discoverImage}
          alt={"discover"}
          width={"100%"}
          scrollPosition={scrollPosition}
        />
        <LazyLoadImage
          className={styles["shadow"]}
          width={"100%"}
          scrollPosition={scrollPosition}
          src={shadow}
          alt={"shadow"}
        />
        <ul>
          <li>{t("home.discoverGlobally")}</li>
          <li>{t("home.discoverEmpowers")}</li>
          <li>{t("home.discoverApplying")}</li>
          <li>{t("home.discoverResulting")}</li>
        </ul>
        <div className={"d-flex justify-content-center"}>
          <Button
            className={styles["discover-button"]}
            label={t("home.discoverOASIS")}
            onClick={() => navigate("/oasis")}
          />
        </div>
      </div>
      <div className={"p-0 col-lg-2 d-lg-flex d-md-none d-sm-none"}>
        <LazyLoadImage
          className={styles["sides"]}
          width={"auto"}
          src={DiscoverRight}
          scrollPosition={scrollPosition}
          alt={"discover left"}
        />
      </div>
    </div>
  );
};

export default trackWindowScroll(Discover);
