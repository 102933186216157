import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./contactUs.module.scss";
import Contact from "../../../components/contact";
import Button from "../../../components/button";
import useResponsiveImage from "../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../assets/images";
import generateMeta from "../../../shared/services/metadataService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { contactList } from "../../../static/contact";
import metaData from "../../../config/metaData";

/**
 * ContactUs component displays contact information and a contact form.
 * @returns {JSX.Element} The rendered ContactUs component.
 */
const ContactUs = (): JSX.Element => {
  /**
   * Retrieves translations for the component using useTranslation hook.
   */
  const { t } = useTranslation();
  /**
   * Retrieves the responsive background image for the ContactUs component.
   * @param {string} ImageKeys.contactUsBackground - The key for the background image.
   * @returns {string} The URL of the responsive background image.
   */
  const backgroundImage = useResponsiveImage(ImageKeys.contactUsBackground);

  return (
    <div
      style={{
        backgroundImage: `url('${backgroundImage}')`,
      }}
      className={styles["contact-container"]}
    >
      {generateMeta({
        title: metaData["contact"].title,
        description: metaData["contact"].description,
      })}
      <h1 className={"main-title"}>{t("contactUs.title")}</h1>
      <div className={"row justify-content-center"}>
        <div
          className={`${styles["list-container"]} col-lg-7 col-md-12 col-sm-12`}
        >
          {contactList.map((item, idx) => (
            <div
              key={idx}
              className={`${styles["list-item"]} align-items-center`}
            >
              <div style={{ alignItems: "center" }} className={"d-flex"}>
                <LazyLoadImage
                  src={item.icon}
                  alt={item.title}
                  width={"auto"}
                  height={"auto"}
                />
                <p>{idx === 0 ? item.longTitle : item.title}</p>
              </div>
              {!!item.onClick && (
                <Button
                  className={styles["view-button"]}
                  label={t(item.buttonTitle)}
                  onClick={item.onClick}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default ContactUs;
