import React from "react";
import { GradientBoxProps } from "../../interfaces/components/gradientBox";
import styles from "./gradientBox.module.scss";
const GradientBox: React.FC<GradientBoxProps> = ({ children, className }) => {
  return (
    <div
      className={`${styles["gradientBox-container"]} d-flex justify-content-center align-items-start ${className}`}
    >
      {children}
    </div>
  );
};

export default GradientBox;
