import React from "react";
import { useTranslation } from "react-i18next";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import {clientsList, mobileClientsList} from "../../../../static/clients";
import styles from "./clients.module.scss";
import useDeviceType from "../../../../shared/hooks/deviceType";
import { DeviceTypes } from "../../../../shared/utils/enums";
import useIsDesktop from "../../../../shared/hooks/isDesktop";

const Clients: React.FC<any> = ({ scrollPosition }) => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const isDesktop = useIsDesktop();

  return (
    <section id={"clients"} className={styles["clients-container"]}>
      <h2>{t("home.clientsTitle")}</h2>
      <div className={"d-flex justify-content-center"}>
        <div
          className={`col-lg-8 col-md-12 col-sm-12 d-flex align-items-center ${styles["clients-list"]}`}
        >
          {(isDesktop ? clientsList : mobileClientsList).map((item) => (
            <LazyLoadImage
              key={item.name}
              src={item.image}
              alt={item.name}
              className={
                ![DeviceTypes.desktop, DeviceTypes.wideDesktop].includes(
                  deviceType,
                )
                  ? `col-md-4 col-sm-4 ${item.isSmall ? styles["small-icon"] : ""}`
                  : ""
              }
              width={"auto"}
              height={"auto"}
              scrollPosition={scrollPosition}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default trackWindowScroll(Clients);
