import { toast } from "react-toastify";
import { TypeOptions } from "react-toastify/dist/types";

/**
 * Displays a global toast notification.
 * @param {Object} options - The options for the toast notification.
 * @param {string} options.message - The message to display in the toast.
 * @param {TypeOptions} options.type - The type of toast ("success" or "error").
 * @param {number} options.duration - The duration (in milliseconds) for which the toast will be displayed.
 * @returns {void}
 */
const showGlobalToast = ({
  message,
  type,
  duration,
}: {
  message: string;
  type: TypeOptions;
  duration: number;
}): void => {
  const toastId = Math.floor(Math.random() * 1000);
  toast(message, {
    position: "bottom-right",
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    type: type,
    toastId,
  });
  toast.play({ id: toastId });
};

/**
 * Displays a success toast notification.
 * @param {string} message - The message to display in the toast.
 * @param {number} [duration=5000] - The duration (in milliseconds) for which the toast will be displayed.
 * @returns {void}
 */
const showSuccessToast = (message: string, duration: number = 5000): void => {
  showGlobalToast({ message, type: "success", duration });
};

/**
 * Displays an error toast notification.
 * @param {string} [message="Something went wrong, please try again later!"] - The message to display in the toast.
 * @param {number} [duration=5000] - The duration (in milliseconds) for which the toast will be displayed.
 * @returns {void}
 */
const showErrorToast = (
  message: string = "Something went wrong, please try again later!",
  duration: number = 5000,
): void => {
  showGlobalToast({ message, type: "error", duration });
};

export const toastService = {
  showSuccessToast,
  showErrorToast,
};
