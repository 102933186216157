import React from "react";
import styles from "./strategyExecution.module.scss";
import { useTranslation } from "react-i18next";
import { strategyExecutionData } from "../../../../static/oasis";
const StrategyExecution = () => {
  const { t } = useTranslation();

  return (
    <section
      id={"strategy-execution"}
      className={"d-flex justify-content-center"}
    >
      <div
        className={`${styles["strategyExecution-container"]} col-lg-7 col-md-12 col-sm-12`}
      >
        <p className={styles["description"]}>{t("oasis.team")}</p>
        <div className={styles["table-container"]}>
          <div className={styles["column1"]}></div>
          <div className={styles["column2"]}></div>
          <table>
            <thead>
              <tr>
                <th>{t("oasis.strategy")}</th>
                <th></th>
                <th>{t("oasis.execution")}</th>
              </tr>
            </thead>
            <tbody>
              {strategyExecutionData.map((item, idx) => (
                <tr className={styles["strategy-row"]} key={idx}>
                  <td width={"40%"} className={styles[`column-strategy`]}>
                    <p>{item.strategy}</p>
                  </td>
                  <td width={"20%"} className={styles[`column-connector`]}>
                    <p>{item.connector}</p>
                  </td>
                  <td
                    width={"40%"}
                    style={{ padding: "0 6%" }}
                    className={styles[`column-execution`]}
                  >
                    <p>{item.execution}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className={styles["result"]}>{t("oasis.result")}</p>
      </div>
    </section>
  );
};

export default StrategyExecution;
