export const strategyExecutionData = [
  {
    strategy: "Well informed & clear",
    connector: "but...",
    execution: "Lacks the alignment, capability and discipline",
    highlighted: true,
  },
  {
    strategy: "Not well informed and/or clear",
    connector: "but...",
    execution: "There is strong alignment, capability and discipline",
    highlighted: false,
  },
  {
    strategy: "Not in place at all",
    connector: "in some cases, not all",
    execution: "Lacks the alignment, capability and discipline",
    highlighted: false,
  },
];
