import React from "react";
import Contact from "../../../components/contact";
import Banner from "./banner";
import styles from "./oasis.module.scss";
import ExperienceInfo from "./experienceInfo";
import StrategyFlow from "./strategyFlow";
import useResponsiveImage from "../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../assets/images";
import generateMeta from "../../../shared/services/metadataService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import StrategyExecution from "./strategyExecution";
import metaData from "../../../config/metaData";

/**
 * Oasis component represents the Discover Oasis page.
 * @returns {JSX.Element} The Oasis component JSX
 */
const Oasis: React.FC = (): JSX.Element => {
  /**
   * Get responsive background image
   */
  const backgroundImage = useResponsiveImage(ImageKeys.oasisBackground);
  return (
    <div className={styles["oasis-container"]}>
      {generateMeta({
        title: metaData.oasis.title,
        description: metaData.oasis.description,
      })}
      <Banner />
      <StrategyExecution />
      <LazyLoadImage
        className={styles["background-image"]}
        src={backgroundImage}
        width={"auto"}
        height={"auto"}
        alt={"background"}
      />
      <div className={styles["relative-container"]}>
        <ExperienceInfo />
        <StrategyFlow />
        <Contact />
      </div>
    </div>
  );
};

export default Oasis;
