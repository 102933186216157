import React from "react";
import OasisStrategy from "../../../../assets/images/shared/oasis/strategy.webp";
import { useTranslation } from "react-i18next";
import styles from "./experienceInfo.module.scss";
import OurStrategy from "../../../../assets/images/shared/home/strategy/ourStrategy.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

/**
 * ExperienceInfo component displays information about the experience related to Oasis.
 * @returns {JSX.Element} The ExperienceInfo component JSX
 */
const ExperienceInfo = (): JSX.Element => {
  /**
   * Retrieves translations for the component using useTranslation hook.
   */
  const { t } = useTranslation();
  return (
    <div
      className={`row ${styles["experience-container"]} justify-content-center`}
    >
      <div className={"col-lg-8 col-md-12 col-sm-12 row align-items-center"}>
        <div className={"col-12 d-flex justify-content-center"}>
          <LazyLoadImage
            className={`${styles["our-strategy"]} col-lg-5 col-md-12 col-sm-12`}
            src={OurStrategy}
            alt={"OASIS Logo"}
            width={"auto%"}
            height={"auto"}
          />
        </div>
        <p className={styles["combined"]}>{t("oasis.combine")}</p>
        <div
          className={"col-12 col-md-12 col-sm-12 d-flex justify-content-center"}
        >
          <LazyLoadImage
            className={`${styles["logo"]} col-lg-5 col-md-12 col-sm-12`}
            src={OasisStrategy}
            alt={"OASIS Logo"}
            width={"auto"}
            height={"auto"}
          />
        </div>
      </div>
    </div>
  );
};

export default ExperienceInfo;
