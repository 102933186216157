import React from "react";
import WelcomeBanner from "./welcomeBanner";
import Discover from "./discover";
import CoreSolutions from "./coreSolutions";
import AboutUs from "./aboutUs";
import styles from "./home.module.scss";
import Contact from "../../../components/contact";
import useResponsiveImage from "../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../assets/images";
import generateMeta from "../../../shared/services/metadataService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Oasis from "./oasis";
import Clients from "./clients";
import SetsUsApart from "./setsUsApart";
import Chart from "./chart";
import metaData from "../../../config/metaData";

/**
 * Home component renders the main page of the application.
 * It includes various sections like WelcomeBanner, Discover, IntegratesStrategy, HowWeDoIt, AboutUs, and Contact.
 */
const Home: React.FC = () => {
  /**
   * Get responsive background image
   */
  const backgroundImage = useResponsiveImage(ImageKeys.homeBackground);
  return (
    <div className={styles["background-container"]}>
      <LazyLoadImage
        className={styles["home-background"]}
        src={backgroundImage}
        alt={"home wave"}
        width={"100%"}
        height={"auto"}
      />
      {generateMeta({
        title: metaData.home.title,
        description: metaData.home.description,
      })}
      <WelcomeBanner />
      <Discover />
      <CoreSolutions />
      <Oasis />
      <AboutUs />
      <Clients />
      <SetsUsApart />
      <Chart />
      <Contact />
    </div>
  );
};

export default Home;
