import { StrategyDataItem } from "../interfaces/static/strategyData";
import organisationStrategy from "../assets/images/shared/oasis/icons/organisationStrategy.svg";
import ambitions from "../assets/images/shared/oasis/icons/ambition.svg";
import scopingInitiatives from "../assets/images/shared/oasis/icons/scopingInitiatives.svg";
import implementation from "../assets/images/shared/oasis/icons/implementation.svg";
import successMeasurment from "../assets/images/shared/oasis/icons/successMeasurment.svg";

const strategyData: StrategyDataItem[] = [
  {
    title: "oasis.organisationStrategyTitle",
    description: "oasis.organisationStrategyDescription",
    position: "top",
    dotColor: "#01CC9B",
    image: organisationStrategy,
  },
  {
    title: "oasis.ambitionsTitle",
    description: "oasis.ambitionsDescription",
    position: "bottom",
    dotColor: "#14A0C0",
    image: ambitions,
  },
  {
    title: "oasis.scopingOfInitiativesTitle",
    description: "oasis.scopingOfInitiativesDescription",
    position: "top",
    dotColor: "#5059AB",
    image: scopingInitiatives,
  },
  {
    title: "oasis.implementationTitle",
    description: "oasis.implementationDescription",
    position: "bottom",
    dotColor: "#09909F",
    image: implementation,
  },
  {
    title: "oasis.successMeasurementTitle",
    description: "oasis.successMeasurementDescription",
    position: "top",
    dotColor: "#7DE314",
    image: successMeasurment,
  },
];

export default strategyData;
