import React from "react";
import OasisLogo from "../../../../assets/images/shared/oasis/oasis-white.webp";
import styles from "./banner.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

/**
 * Banner component displays the banner section of the Oasis page.
 * @returns {JSX.Element} The Banner component JSX
 */
const Banner = (): JSX.Element => {
  return (
    <div className={`${styles["banner-container"]} row justify-content-center`}>
      <div className={"col-lg-9 col-md-12 col-sm-12"}>
        <div className={"d-flex justify-content-center"}>
          <LazyLoadImage
            className={styles["logo"]}
            src={OasisLogo}
            alt={"OASIS Logo"}
            width={"auto%"}
            height={"auto"}
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
