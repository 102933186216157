import { useEffect, useState } from "react";
import { DeviceTypes } from "../utils/enums";
import { isBrowserDefined } from "../services/browserService";

/**
 * Function to determine the device type based on its width.
 * @param {number} width - The width of the device.
 * @returns {DeviceTypes} The type of device.
 */
const getDeviceType = (width: number): DeviceTypes => {
  if (width <= 743) {
    return DeviceTypes.mobile;
  } else if (width <= 1024) {
    return DeviceTypes.tablet;
  } else if (width <= 1440) {
    return DeviceTypes.desktop;
  }
  return DeviceTypes.wideDesktop;
};

/**
 * Custom hook to determine the type of device based on its width.
 * @returns {DeviceTypes} The type of device.
 */
const useDeviceType = (): DeviceTypes => {
  /**
   * State to hold the current device type.
   */
  const [deviceType, setDeviceType] = useState<DeviceTypes>(
    // On the server, `window` is undefined, so default to a safe value.
    isBrowserDefined() ? getDeviceType(window.innerWidth) : DeviceTypes.desktop,
  );

  /**
   * Effect to handle window resize event and update the device type accordingly.
   */
  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType(window.innerWidth));
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return deviceType;
};

export default useDeviceType;
