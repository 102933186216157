import React from "react";
import styles from "./oasis.module.scss";
import GradientBox from "../../../../components/gradientBox";
import oasisLogo from "../../../../assets/images/shared/oasis/oasis-white.webp";
import Button from "../../../../components/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const Oasis = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <section
      id={"oasis"}
      className={`${styles["oasis-section"]} row justify-content-center`}
    >
      <div
        className={`${styles["oasis-container"]} col-lg-7 col-md-12 col-sm-12`}
      >
        <div className={"row justify-content-center"}>
          <GradientBox
            className={`${styles["custom-col-5"]} col-md-12 col-sm-12 ${styles["oasis-item"]} ${styles['logo']}`}
          >
            <img src={oasisLogo} alt={"oasis"} width={"auto"} height={"auto"} />
          </GradientBox>
        </div>
        <div className={"row justify-content-between mt-2"}>
          <GradientBox
            className={`${styles["custom-col-4"]} col-md-12 col-sm-12 ${styles["oasis-item"]}`}
          >
            <p>{t("home.oasisStrategyExecutionGap")}</p>
          </GradientBox>
          <GradientBox
            className={`${styles["custom-col-4"]} col-md-12 col-sm-12 ${styles["oasis-item"]}`}
          >
            <p>{t("home.oasisBusinessTechnologyService")}</p>
          </GradientBox>
          <GradientBox
            className={`${styles["custom-col-4"]} col-md-12 col-sm-12 ${styles["oasis-item"]}`}
          >
            <p>{t("home.oasisImplementingSupport")}</p>
          </GradientBox>
        </div>
        <div className={"row justify-content-center mt-2"}>
          <Button
            className={`${styles["custom-col-4"]} ${styles["readMore-btn"]} col-md-8 col-sm-12`}
            label={t("app.readMore")}
            onClick={() => navigate("/oasis")}
            buttonType={"dark"}
          />
        </div>
      </div>
    </section>
  );
};

export default Oasis;
