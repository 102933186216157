import React from "react";
import Contact from "../../../components/contact";
import { useTranslation } from "react-i18next";
import styles from "./ourStory.module.scss";
import useResponsiveImage from "../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../assets/images";
import generateMeta from "../../../shared/services/metadataService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import purpose from "../../../assets/images/shared/ourStory/purpose.webp";
import metaData from "../../../config/metaData";
const OurStory = () => {
  /**
   * Retrieves translations for AboutUs component using useTranslation hook.
   */
  const { t } = useTranslation();
  /**
   * Retrieves the responsive background image for the AboutUs component.
   * @param {string} ImageKeys.aboutBackground - The key for the background image.
   * @returns {string} The URL of the responsive background image.
   */
  const backgroundImage = useResponsiveImage(ImageKeys.ourStoryBackground);
  return (
    <div
      className={styles["about-container"]}
      style={{
        backgroundImage: `url('${backgroundImage}')`,
      }}
    >
      {generateMeta({
        title: metaData["our-story"].title,
        description: metaData["our-story"].description,
      })}
      <h1 className={`${styles["main-title"]} main-title`}>
        {t("ourStory.title")}
      </h1>
      <div className={`row justify-content-center ${styles["text-container"]}`}>
        <div className={"col-lg-9 col-md-12 col-sm-12"}>
          <p>{t("ourStory.companyDescription")}</p>
          <p>{t("ourStory.companyMission")}</p>
          <p>{t("ourStory.companyPhilosophy")}</p>
          <p>{t("ourStory.companyVision")}</p>
          <LazyLoadImage
            src={purpose}
            alt={"purpose"}
            width={"100%"}
            height={"auto"}
          />
          <p className={styles["note"]}>{t("ourStory.leadershipPhilosophy")}</p>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default OurStory;
