import React, { useCallback, useState } from "react";
import logo from "../../../assets/images/shared/icons/logo.webp";
import styles from "./header.module.scss";
import { sideMenu } from "../../../config/sideMenu";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import { NavLink } from "react-router-dom";
import drawer from "../../../assets/images/shared/icons/menu.svg";
import closeIcon from "../../../assets/images/shared/icons/close.svg";
import { scrollToContact } from "../../../shared/services/contactService";

/**
 * Header component for the application.
 * Renders the navigation bar and side menu toggle.
 */
const Header: React.FC = () => {
  /**
   * React-i18next translation hook.
   */
  const { t } = useTranslation();
  /**
   * State to manage the opening/closing of the side menu.
   */
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);

  /**
   * Toggles the visibility of the side menu.
   */
  const toggleMenu = useCallback(() => {
    const newState = !isSideMenuOpen;
    setIsSideMenuOpen(newState);
  }, [isSideMenuOpen]);

  return (
    <header>
      <div className={styles["nav-wrapper"]}>
        <nav className={`${styles["navbar"]} row`}>
          <NavLink
            aria-label={"Return to homepage"}
            className={styles["image-link"]}
            to={"/"}
          >
            <img src={logo} alt="Return to homepage" />
          </NavLink>
          <button
            className={`${styles["drawer-btn"]} d-lg-none`}
            onClick={toggleMenu}
            aria-label={"Toggle side menu"}
          >
            <img src={drawer} width={32.34} height={34} alt={"toggle menu"} />
          </button>
          <ul
            className={`${styles["nav"]} d-lg-flex d-md-none d-sm-none d-xs-none`}
          >
            {sideMenu.map((item) => (
              <li key={item.path} className={styles["nav-item"]}>
                <NavLink to={item.path}>{t(item.title)}</NavLink>
              </li>
            ))}
          </ul>
          <Button
            className={`d-sm-none d-xs-none d-md-none d-lg-block ${styles["consultation"]}`}
            label={t("menu.bookConsultation")}
            onClick={() => scrollToContact()}
          />
        </nav>
      </div>
      <div className={isSideMenuOpen ? styles["side-menu-open"] : ""}>
        <div
          onClick={toggleMenu}
          className={`${styles["overlay"]} d-lg-none`}
        ></div>
        <div
          className={`d-lg-none d-md-block d-sm-block ${styles["side-menu"]}`}
        >
          <div className={styles["side-menu-items-container"]}>
            <div
              className={`${styles["side-menu-header"]} d-flex align-items-center`}
            >
              <button
                className={`${styles["drawer-btn"]} d-lg-none`}
                onClick={toggleMenu}
                aria-label={"Toggle side menu"}
              >
                <img
                  src={closeIcon}
                  width={26.87}
                  height={26.87}
                  alt={"close menu"}
                />
              </button>
              <h2>{t("menu.title")}</h2>
            </div>
            {sideMenu.map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                onClick={toggleMenu}
                className={({ isActive, isPending }) =>
                  `${styles["side-menu-item"]} ${isActive && styles["active"]}`
                }
              >
                <div className={"d-flex align-items-center"}>
                  <p>{t(item.title)}</p>
                </div>
              </NavLink>
            ))}
            <Button
              className={styles["consultation-button"]}
              label={t("home.freeHourConsultation")}
              onClick={() => {
                toggleMenu();
                scrollToContact();
              }}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
