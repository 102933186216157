import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/button";
import styles from "./welcomeBanner.module.scss";
import useResponsiveImage from "../../../../shared/hooks/responsiveImage";
import { ImageKeys } from "../../../../assets/images";
import { scrollToContact } from "../../../../shared/services/contactService";
import { LazyLoadImage } from "react-lazy-load-image-component";

/**
 * WelcomeBanner component displays a welcome banner with an image and a call-to-action button.
 * @returns {JSX.Element} The WelcomeBanner component JSX
 */
const WelcomeBanner = (): JSX.Element => {
  /**
   * Retrieves translations for the component using useTranslation hook.
   */
  const { t } = useTranslation();
  /**
   *  Get responsive welcome image
   */
  const welcomeImage = useResponsiveImage(ImageKeys.homeWelcome);
  return (
    <div className={styles["welcome-banner"]}>
      <LazyLoadImage
        src={welcomeImage}
        alt={"welcome"}
        width={"100%"}
        visibleByDefault
      />
      <div
        className={`${styles["overlay"]} d-flex flex-column justify-content-center align-items-center`}
      >
        <h1>{t("home.title")}</h1>
        <Button
          className={styles["freeConsultation-button"]}
          label={t("home.freeHourConsultation")}
          onClick={() => scrollToContact()}
        />
      </div>
    </div>
  );
};

export default WelcomeBanner;
