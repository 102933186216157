/**
 * Enum representing different types of devices.
 * @readonly
 * @enum {string}
 */
export enum DeviceTypes {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
  wideDesktop = "wideDesktop",
}
