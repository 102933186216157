import React from "react";
import styles from "./setsUsApart.module.scss";
import { useTranslation } from "react-i18next";
import { setsUsApartList } from "../../../../static/setsUsApart";
import SetsUsApartItem from "../../../../components/setsUsApartItem";
const SetsUsApart = () => {
  const { t } = useTranslation();

  return (
    <section
      id={"sets-us-apart"}
      className={`${styles["setsUsApart-container"]} d-flex justify-content-center p-0`}
    >
      <div className={`col-lg-8 col-md-12 col-sm-12 `}>
        <h2>{t("home.setsUsApartTitle")}</h2>
        <div
          className={`row justify-content-between ${styles["list-container"]}`}
        >
          {setsUsApartList.map((item, idx) => (
            <SetsUsApartItem
              key={item.label}
              label={t(item.label)}
              number={idx + 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SetsUsApart;
