import { isBrowserDefined } from "./browserService";
import { Helmet } from "react-helmet";
interface MetaData {
  title: string;
  slug?: string;
  description?: string;
  image?: string;
  keywords?: string[];
}

/**
 * Generates meta tags for the document head based on the provided metadata.
 * @param {Object} metaData - The metadata object containing title, slug, description, image, and keywords.
 * @param {string} metaData.title - The title of the document.
 * @param {string} [metaData.slug=""] - The slug of the document.
 * @param {string} [metaData.description="main description"] - The description of the document.
 * @param {string} [metaData.image=""] - The URL of the image associated with the document.
 * @param {string[]} [metaData.keywords=[]] - An array of keywords associated with the document.
 * @returns {JSX.Element} The Helmet component containing the generated meta tags.
 */
const generateMeta = ({
  title,
  slug = "",
  description = "main description",
  image = "",
  keywords = [],
}: MetaData): JSX.Element => {
  const descriptionText = extractContent(description);

  return (
    <Helmet>
      {/* Keywords */}
      {keywords.length > 0 && (
        <meta
          name="keywords"
          content={keywords.map((item) => item).join(", ")}
        />
      )}
      <title>{title}</title>

      {/* Normal */}
      <meta name="title" content={title} />
      <meta name="description" content={descriptionText} />
      {image && <meta name="image" content={image} />}

      {/* Google */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={descriptionText} />
      {image && <meta itemProp="image" content={image} />}

      {/* Facebook + LinkedIn + WhatsApp */}
      <meta
        property="og:url"
        content={`https://canguru.com.au/blogs/${slug}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={descriptionText} />
      {image && <meta property="og:image" content={image} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={descriptionText} />
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};

/**
 * Extracts plain text content from HTML string and limits the word count.
 * @param {string} html - The HTML string to extract content from.
 * @param {number} [wordLimit=50] - The maximum number of words to include in the excerpt.
 * @returns {string} The extracted plain text content with a word limit.
 */
const extractContent = (html: string, wordLimit: number = 50): string => {
  if (!isBrowserDefined()) return "";
  const element = document.createElement("div");
  element.innerHTML = html;

  const textContent = element.textContent || "";

  const words = textContent.split(/\s+/);
  const excerpt = words.slice(0, wordLimit).join(" ");

  return words.length > wordLimit ? `${excerpt} ... (Read more)` : excerpt;
};

export default generateMeta;
