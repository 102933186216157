import { ImageItem } from "../../interfaces/assets/images";

import backgroundAboutMobile from "./mobile/about/background.webp";
import backgroundAboutTablet from "./tablet/about/background.webp";
import backgroundAboutDesktop from "./desktop/about/background.webp";

import backgroundContactUsMobile from "./mobile/contactUs/background.webp";
import backgroundContactUsTablet from "./tablet/contact/background.webp";
import backgroundContactUsDesktop from "./desktop/contactUs/background.webp";

import backgroundHomeMobile from "./mobile/home/background.webp";
import backgroundHomeTablet from "./tablet/home/background.webp";
import backgroundHomeDesktop from "./desktop/home/background.webp";
import backgroundHomeWideDesktop from "./wide/home/background.webp";

import backgroundOasisMobile from "./mobile/oasis/background.webp";
import backgroundOasisTablet from "./tablet/oasis/background.webp";
import backgroundOasisDesktop from "./desktop/oasis/background.webp";

import backgroundBusinessSolutionMobile from "./mobile/businessSolution/background.webp";
import backgroundBusinessSolutionTablet from "./tablet/businessSolution/background.webp";
import backgroundBusinessSolutionDesktop from "./desktop/businessSolution/background.webp";

import backgroundHowWeDoIt1Mobile from "./mobile/home/howWeDoIt/background1.svg";
import backgroundHowWeDoIt1Desktop from "./desktop/home/howWeDoIt/background1.svg";

import backgroundHowWeDoIt2Mobile from "./mobile/home/howWeDoIt/background2.svg";
import backgroundHowWeDoIt2Desktop from "./desktop/home/howWeDoIt/background2.svg";

import backgroundHowWeDoIt3Mobile from "./mobile/home/howWeDoIt/background3.svg";
import backgroundHowWeDoIt3Desktop from "./desktop/home/howWeDoIt/background3.svg";

import backgroundHowWeDoIt4Mobile from "./mobile/home/howWeDoIt/background4.svg";
import backgroundHowWeDoIt4Desktop from "./desktop/home/howWeDoIt/background4.svg";

import backgroundHowWeDoIt5Mobile from "./mobile/home/howWeDoIt/background5.svg";
import backgroundHowWeDoIt5Desktop from "./desktop/home/howWeDoIt/background5.svg";

import backgroundHowWeDoIt6Mobile from "./mobile/home/howWeDoIt/background6.svg";
import backgroundHowWeDoIt6Desktop from "./desktop/home/howWeDoIt/background6.svg";

import backgroundHowWeDoIt7Mobile from "./mobile/home/howWeDoIt/background7.svg";
import backgroundHowWeDoIt7Desktop from "./desktop/home/howWeDoIt/background7.svg";

import backgroundHowWeDoIt8Mobile from "./mobile/home/howWeDoIt/background8.svg";
import backgroundHowWeDoIt8Desktop from "./desktop/home/howWeDoIt/background8.svg";

import backgroundHomeWelcomeMobile from "./mobile/home/welcome.webp";
import backgroundHomeWelcomeDesktop from "./desktop/home/welcome.webp";

import backgroundHomeDiscoverMobile from "./mobile/home/discover/discoverOASIS.webp";
import backgroundHomeDiscoverDesktop from "./desktop/home/discover/discoverOASIS.webp";

import contactBackgroundMobile from "./mobile/home/contact.webp";
import contactBackgroundDesktop from "./shared/contactUs/background.webp";

import ourStoryBackgroundDesktop from "./desktop/ourStory/background.webp";

export enum ImageKeys {
  aboutBackground = "aboutBackground",
  contactUsBackground = "contactUsBackground",
  homeBackground = "homeBackground",
  oasisBackground = "oasisBackground",
  businessSolution = "businessSolution",
  howWeDoIt1 = "howWeDoIt1",
  howWeDoIt2 = "howWeDoIt2",
  howWeDoIt3 = "howWeDoIt3",
  howWeDoIt4 = "howWeDoIt4",
  howWeDoIt5 = "howWeDoIt5",
  howWeDoIt6 = "howWeDoIt6",
  howWeDoIt7 = "howWeDoIt7",
  howWeDoIt8 = "howWeDoIt8",
  homeWelcome = "homeWelcome",
  homeDiscover = "homeDiscover",
  ourStoryBackground = "ourStoryBackground",
  contactBackground = "contactBackground",
}

export const images: ImageItem = {
  [ImageKeys.aboutBackground]: {
    mobile: backgroundAboutMobile,
    tablet: backgroundAboutTablet,
    desktop: backgroundAboutDesktop,
  },
  [ImageKeys.contactUsBackground]: {
    mobile: backgroundContactUsMobile,
    tablet: backgroundContactUsTablet,
    desktop: backgroundContactUsDesktop,
  },
  [ImageKeys.homeBackground]: {
    mobile: backgroundHomeMobile,
    tablet: backgroundHomeTablet,
    desktop: backgroundHomeDesktop,
    wideDesktop: backgroundHomeWideDesktop,
  },
  [ImageKeys.oasisBackground]: {
    mobile: backgroundOasisMobile,
    tablet: backgroundOasisTablet,
    desktop: backgroundOasisDesktop,
  },
  [ImageKeys.businessSolution]: {
    mobile: backgroundBusinessSolutionMobile,
    tablet: backgroundBusinessSolutionTablet,
    desktop: backgroundBusinessSolutionDesktop,
  },
  [ImageKeys.howWeDoIt1]: {
    mobile: backgroundHowWeDoIt1Mobile,
    desktop: backgroundHowWeDoIt1Desktop,
  },
  [ImageKeys.howWeDoIt2]: {
    mobile: backgroundHowWeDoIt2Mobile,
    desktop: backgroundHowWeDoIt2Desktop,
  },
  [ImageKeys.howWeDoIt3]: {
    mobile: backgroundHowWeDoIt3Mobile,
    desktop: backgroundHowWeDoIt3Desktop,
  },
  [ImageKeys.howWeDoIt4]: {
    mobile: backgroundHowWeDoIt4Mobile,
    desktop: backgroundHowWeDoIt4Desktop,
  },
  [ImageKeys.howWeDoIt5]: {
    mobile: backgroundHowWeDoIt5Mobile,
    desktop: backgroundHowWeDoIt5Desktop,
  },
  [ImageKeys.howWeDoIt6]: {
    mobile: backgroundHowWeDoIt6Mobile,
    desktop: backgroundHowWeDoIt6Desktop,
  },
  [ImageKeys.howWeDoIt7]: {
    mobile: backgroundHowWeDoIt7Mobile,
    desktop: backgroundHowWeDoIt7Desktop,
  },
  [ImageKeys.howWeDoIt8]: {
    mobile: backgroundHowWeDoIt8Mobile,
    desktop: backgroundHowWeDoIt8Desktop,
  },
  [ImageKeys.homeWelcome]: {
    mobile: backgroundHomeWelcomeMobile,
    desktop: backgroundHomeWelcomeDesktop,
  },
  [ImageKeys.homeDiscover]: {
    mobile: backgroundHomeDiscoverMobile,
    desktop: backgroundHomeDiscoverDesktop,
  },
  [ImageKeys.ourStoryBackground]: {
    mobile: ourStoryBackgroundDesktop,
    desktop: ourStoryBackgroundDesktop,
  },
  [ImageKeys.contactBackground]: {
    mobile: contactBackgroundMobile,
    desktop: contactBackgroundDesktop,
  },
};
