import React from "react";
import styles from "./chart.module.scss";
import {useTranslation} from "react-i18next";
import {LazyLoadImage} from "react-lazy-load-image-component";
import charImage from "../../../../assets/images/shared/home/chart.webp";
import useDeviceType from "../../../../shared/hooks/deviceType";
import {DeviceTypes} from "../../../../shared/utils/enums";

const Chart = () => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  return (
    <section
      id={"chart"}
      className={`${styles["chart-section"]} relative d-flex justify-content-center`}
    >
      <div
        className={`${styles["chart-container"]} col-lg-8 col-md-12 col-sm-12`}
      >
        <h2>{t("home.chartTitle")}</h2>
        <p>{t("home.chartDescription")}</p>
        <div className={"row"}>
          <div className={`col-lg-6 col-md-8 ${deviceType === DeviceTypes.mobile ? 'col-sm-12' : 'col-sm-8'} p-0`}>
            <LazyLoadImage
              src={charImage}
              alt={"chart"}
              width={"100%"}
              height={"auto"}
            />
          </div>
          <div className={`col-lg-6 col-md-4 ${deviceType === DeviceTypes.mobile ? 'col-sm-12' : 'col-sm-4'}`}>
            <p className={styles["gap"]}>{t("home.chartWideningGap")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chart;
