import bhp from "../assets/images/shared/icons/clients/bhp.svg";
import cargill from "../assets/images/shared/icons/clients/cargill.svg";
import boq from "../assets/images/shared/icons/clients/boq.svg";
import clearDynamics from "../assets/images/shared/icons/clients/clearDynamics.svg";
import xinja from "../assets/images/shared/icons/clients/xinja.svg";
import au from "../assets/images/shared/icons/clients/au.svg";
import policeBank from "../assets/images/shared/icons/clients/policeBank.svg";
import parkAgility from "../assets/images/shared/icons/clients/parkAgilty.svg";
import radian from "../assets/images/shared/icons/clients/radian.svg";
import allaboutxpert from "../assets/images/shared/icons/clients/allaboutxpert.svg";
import bma from "../assets/images/shared/icons/clients/bma.svg";
import vale from "../assets/images/shared/icons/clients/vale.svg";
import samarco from "../assets/images/shared/icons/clients/samarco.svg";
import paxus from "../assets/images/shared/icons/clients/paxus.svg";
import goldCoast from "../assets/images/shared/icons/clients/goldCoast.svg";
import petStock from "../assets/images/shared/icons/clients/petStock.svg";
import latitude from "../assets/images/shared/icons/clients/latitude.svg";
import australianGovernment from "../assets/images/shared/icons/clients/australianGovernment.svg";
import queensLandGovernment from "../assets/images/shared/icons/clients/queensLandGovernement.svg";
import bovis from "../assets/images/shared/icons/clients/bovis.svg";
import theHills from "../assets/images/shared/icons/clients/theHills.svg";

export const clientsList = [
  { image: bhp, name: "BHP" },
  { image: cargill, name: "Cargill" },
  { image: boq, name: "BOQ" },
  { image: clearDynamics, name: "Clear Dynamics" },
  { image: xinja, name: "Xinja" },
  { image: au, name: "AU" },
  { image: policeBank, name: "Police Bank" },
  { image: parkAgility, name: "Park Agility" },
  { image: radian, name: "Radian" },
  { image: allaboutxpert, name: "Allaboutxpert" },
  { image: bma, name: "BMA" },
  { image: vale, name: "Vale" },
  { image: samarco, name: "Samarco" },
  { image: paxus, name: "Paxus" },
  { image: goldCoast, name: "Gold Coast" },
  { image: petStock, name: "PetStock" },
  { image: latitude, name: "Latitude" },
  { image: australianGovernment, name: "Australian Government", isSmall: true },
  { image: queensLandGovernment, name: "Queensland Government", isSmall: true },
  { image: bovis, name: "Bovis", isSmall: true },
  { image: theHills, name: "The Hills" },
];

export const mobileClientsList = [
  { image: bhp, name: "BHP" },
  { image: cargill, name: "Cargill" },
  { image: boq, name: "BOQ" },
  { image: clearDynamics, name: "Clear Dynamics" },
  { image: xinja, name: "Xinja" },
  { image: au, name: "AU" },
  { image: policeBank, name: "Police Bank" },
  { image: parkAgility, name: "Park Agility" },
  { image: radian, name: "Radian" },
  { image: bma, name: "BMA" },
  { image: vale, name: "Vale" },
  { image: allaboutxpert, name: "Allaboutxpert", isSmall: true },
  { image: samarco, name: "Samarco" },
  { image: paxus, name: "Paxus" },
  { image: goldCoast, name: "Gold Coast" },
  { image: petStock, name: "PetStock" },
  { image: latitude, name: "Latitude" },
  { image: australianGovernment, name: "Australian Government", isSmall: true },
  { image: queensLandGovernment, name: "Queensland Government", isSmall: true },
  { image: bovis, name: "Bovis", isSmall: true },
  { image: theHills, name: "The Hills" },
];
