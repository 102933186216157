import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../screens/content/home";
import Oasis from "../screens/content/oasis";
import "../translation/index";
import ContactUs from "../screens/content/contactUs";
import NotFound from "../screens/content/notFound";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import OurStory from "../screens/content/ourStory";
import CustomLayout from "../screens/customLayout";

/**
 * Main router component for routing within the application.
 * Provides routing for different screens and components.
 */
const MainRouter = () => {
  return (
    <Provider store={store}>
      <CustomLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/oasis" element={<Oasis />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </CustomLayout>
    </Provider>
  );
};

export default MainRouter;
