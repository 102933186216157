import React from "react";
import LogoWhite from "../../../assets/images/shared/icons/logo-white.webp";
import Oasis from "../../../assets/images/shared/oasis/oasis-white.webp";
import { sideMenu } from "../../../config/sideMenu";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";
import { NavLink } from "react-router-dom";
import { contactList, socialMediaList } from "../../../static/contact";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer: React.FC = () => {
  /**
   * React-i18next translation hook.
   */
  const { t } = useTranslation();

  return (
    <footer className={`row ${styles["footer-container"]}`}>
      <div
        className={
          "col-lg-3 col-md-6 col-sm-12 d-flex flex-column align-items-lg-center align-items-md-start align-items-sm-start"
        }
      >
        <img src={LogoWhite} alt={"logo"} width={"198px"} height={"45px"} />
        <img src={Oasis} alt={"Oasis logo"} width={"auto"} height={"auto"} />
      </div>
      <div className={"col-lg-3 col-md-6 col-sm-12"}>
        <ul>
          {sideMenu.map((item) => (
            <li key={item.path}>
              <NavLink to={item.path}>{t(item.title)}</NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className={"col-lg-3 col-md-6 col-sm-12"}>
        {contactList.map((item) => (
          <button
            aria-label={item.title}
            key={item.title}
            onClick={item.onClick}
            className={`d-flex align-items-center ${styles["contact-item"]}`}
          >
            <LazyLoadImage
              className={"contact-item-image"}
              src={item.icon}
              alt={item.title}
              width={"15"}
              height={"15"}
            />
            <p>{item.title}</p>
          </button>
        ))}
      </div>
      <div
        className={`col-lg-3 col-md-6 col-sm-12 row align-items-start ${styles["social-container"]}`}
      >
        {socialMediaList.map((item) => (
          <button
            key={item.label}
            className={`${styles["social-item"]} ${styles[item.label]}`}
            onClick={item.onClick}
            aria-label={item.label}
          >
            <item.icon size={18} />
          </button>
        ))}
      </div>
      <div className={"col-12 row justify-content-center"}>
        <p className={styles["copyright"]}>
          {t("footer.copyright")} {new Date().getFullYear()}
          {t("footer.allRightReserved")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
