import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./about.module.scss";
import logo from "../../../../assets/images/shared/icons/aboutLogo.svg";

/**
 * AboutUs component displays information about the company.
 * @returns {JSX.Element} The AboutUs component JSX
 */
const AboutUs = (): JSX.Element => {
  /**
   * Retrieves translations for the component using useTranslation hook.
   */
  const { t } = useTranslation();

  return (
    <section
      id={"about"}
      className={`row justify-content-between align-items-center ${styles["about-container"]}`}
    >
      <div
        className={`${styles["left-container"]} col-lg-4 col-md-12 d-sm-12 align-items-center d-flex justify-content-end`}
      >
        <img src={logo} alt={"flowforge"} width={"auto"} height={"auto"} />
      </div>
      <div
        className={`${styles["right-container"]} col-lg-8 col-md-12 col-sm-12`}
      >
        <p>{t("home.aboutDescription")}</p>
      </div>
    </section>
  );
};

export default AboutUs;
