import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../button";
import styles from "./contact.module.scss";
import "../../shared/styles/form.scss";
import Checkbox from "../checkbox";
import { ContactForm } from "../../interfaces/components/contact";
import { toastService } from "../../shared/utils/toast";
import { handleChange, validateEmail } from "../../shared/utils/formHelper";
import { sendContact } from "./actions";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useResponsiveImage from "../../shared/hooks/responsiveImage";
import {ImageKeys} from "../../assets/images";

/**
 * Array containing mandatory fields of the contact form.
 * @type {(keyof ContactForm)[]}
 */
const mandatoryFields: (keyof ContactForm)[] = [
  "first_name",
  "last_name",
  "recaptcha",
  "email",
];

/**
 * Contact component.
 * @returns {JSX.Element} - JSX element representing the contact component.
 */
const Contact = (): JSX.Element => {
  /**
   * React-i18next translation hook.
   */
  const { t } = useTranslation();
  /**
   * State to track if the form has been submitted.
   */
  const [submitted, setSubmitted] = useState<boolean>(false);
  /**
   * State to manage the form data.
   */
  const [form, setForm] = useState<ContactForm>({
    first_name: "",
    last_name: "",
    email: "",
    subscribe_updates: false,
    recaptcha: false,
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const contactBackground = useResponsiveImage(ImageKeys.contactBackground);
  /**
   * Handles form submission.
   */
  const handleSubmit = async () => {
    setSubmitted(true);
    const missingFields = mandatoryFields.filter(
      (field: keyof ContactForm) => !form[field],
    );
    if (missingFields.length === 0) {
      if (!validateEmail(form.email)) {
        toastService.showErrorToast("Please enter a valid email address!");
        return;
      }
      const temp = { ...form };
      temp["subscribe_updates"] = temp["subscribe_updates"] ? "1" : "0";
      const res = await sendContact(temp);
      if (!res) return;
      setForm({
        first_name: "",
        last_name: "",
        email: "",
        subscribe_updates: false,
        recaptcha: false,
      });
      recaptchaRef.current?.reset();
      toastService.showSuccessToast(t("contact.submitted"));
      setSubmitted(false);
    } else {
      toastService.showErrorToast(t("app.requiredFieldsError"));
    }
  };

  const onChange = (token: string | null) => {
    console.log(token);
    handleChange("recaptcha", !!token, form, setForm);
  };

  return (
    <section
      id={"contact"}
      className={`row justify-content-center ${styles["contact-container"]}`}
    >
      <div
        className={`${styles["contact-background"]} col-lg-8 col-md-12 col-sm-12`}
      >
        <LazyLoadImage
          src={contactBackground}
          alt={"contact"}
          width={"100%"}
          height={"auto"}
        />
        <div className={"row"}>
          <div
            className={`col-lg-6 col-md-12 col-sm-12 ${styles["title-container"]}`}
          >
            <h2 className={styles["title"]}>{t("contact.title")}</h2>
            <p className={styles["description"]}>{t("contact.description")}</p>
          </div>
          <div
            className={`row col-lg-6 col-md-12 col-sm-12 justify-content-sm-center ${styles["form-container"]}`}
          >
            <div className={`col-lg-6 col-md-12 col-sm-12 p-1 pl-col-0`}>
              <label htmlFor="firstName">{t("contact.firstName")}</label>
              <input
                id="firstName"
                value={form["first_name"]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("first_name", e.target.value, form, setForm)
                }
                aria-label={"first name"}
                className={
                  submitted && !form.first_name ? styles["invalid-input"] : ""
                }
              />
            </div>
            <div className={`col-lg-6 col-md-12 col-sm-12 p-1 pl-col-0`}>
              <label htmlFor="lastName">{t("contact.lastName")}</label>
              <input
                id="lastName"
                value={form["last_name"]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("last_name", e.target.value, form, setForm)
                }
                aria-label={"last name"}
                className={
                  submitted && !form.last_name ? styles["invalid-input"] : ""
                }
              />
            </div>
            <div className={`col-12 p-0`}>
              <label htmlFor={"email"}>{t("contact.email")}</label>
              <input
                id="email"
                value={form["email"]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("email", e.target.value, form, setForm)
                }
                type={"email"}
                aria-label={"email"}
                className={
                  submitted && !form.email ? styles["invalid-input"] : ""
                }
              />
            </div>
            <div className={`col-12 p-0`}>
              <Checkbox
                onValueChange={(val: boolean) =>
                  handleChange("subscribe_updates", val, form, setForm)
                }
                value={form["subscribe_updates"] as boolean}
                label={t("contact.subscribeUpdates")}
              />
            </div>
            <div className={"col-12 d-flex justify-content-center"}>
              <ReCAPTCHA
                className={
                  submitted && !form.recaptcha
                    ? styles["recaptcha-invalid"]
                    : styles["recaptcha"]
                }
                ref={recaptchaRef}
                sitekey={config.recaptchaKey}
                onChange={onChange}
              />
            </div>
            <div className={"col-12 d-flex justify-content-center p-0"}>
              <Button
                className={styles["submit"]}
                label={t("contact.submit")}
                onClick={handleSubmit}
                buttonType={"dark"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
