import { isBrowserDefined } from "../shared/services/browserService";
import config from "../config";
import MapIcon from "../assets/images/shared/contactUs/map.svg";
import CallIcon from "../assets/images/shared/contactUs/call.svg";
import EmailIcon from "../assets/images/shared/contactUs/email.svg";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";

/**
 * Opens the specified link in a new tab.
 * @param {string} link - The link to be opened.
 */
const openLink = (link: string) => {
  if (!isBrowserDefined()) return;
  window.open(link, "_blank");
};

/**
 * List of contact information items
 */
export const contactList = [
  {
    title: config.address,
    longTitle: config.addressLong,
    icon: MapIcon,
    buttonTitle: "contactUs.addressButton",
  },
  {
    title: config.phoneNumber,
    icon: CallIcon,
    buttonTitle: "contactUs.numberButton",
    onClick: () => openLink(`tel:${config.phoneNumber}`),
  },
  {
    title: config.phoneNumber2,
    icon: CallIcon,
    buttonTitle: "contactUs.numberButton",
    onClick: () => openLink(`tel:${config.phoneNumber2}`),
  },
  {
    title: config.email,
    icon: EmailIcon,
    buttonTitle: "contactUs.emailButton",
    onClick: () => openLink(`mailto:${config.email}`),
  },
];

export const socialMediaList = [
  {
    label: "linkedin",
    icon: FaLinkedin,
    onClick: () => openLink(config.linkedIn),
  },
  {
    label: "facebook",
    icon: FaFacebook,
    onClick: () => openLink(config.facebook),
  },
  {
    label: "instagram",
    icon: PiInstagramLogoFill,
    onClick: () => openLink(config.instagram),
  },
  {
    label: "youtube",
    icon: FaYoutube,
    onClick: () => openLink(config.youtube),
  },
];
