import React from "react";
import styles from "./setsUsApartItem.module.scss";
import {SetsUsApartProps} from "../../interfaces/components/setsUsApartItem";
import useDeviceType from "../../shared/hooks/deviceType";
import {DeviceTypes} from "../../shared/utils/enums";

const SetsUsApartItem: React.FC<SetsUsApartProps> = ({ label, number }) => {
  const deviceType = useDeviceType();
  return (
    <div
      className={`${styles["setsUsApartItem-container"]} relative d-flex ${deviceType === DeviceTypes.mobile ? 'align-items-center' : 'align-items-end'}`}
    >
      <h4 className={styles["number"]}>
        {number < 10 ? `0${number}` : number}
      </h4>
      <p>{label}</p>
    </div>
  );
};

export default SetsUsApartItem;
