import { SideMenuItem } from "../interfaces/config/sideMenu";

const sideMenuDesktop: SideMenuItem[] = [
  {
    title: "menu.ourStory",
    path: "our-story",
  },
  {
    title: "menu.oasis",
    path: "oasis",
  },
  {
    title: "menu.contact",
    path: "contact-us",
  },
];

/**
 * The array of side menu items for the application.
 * @type {SideMenuItem[]}
 */
export const sideMenu: SideMenuItem[] = sideMenuDesktop;
