import background1 from "../assets/images/shared/home/reasons/1.svg";
import background2 from "../assets/images/shared/home/reasons/2.svg";
import background3 from "../assets/images/shared/home/reasons/3.svg";
import background4 from "../assets/images/shared/home/reasons/4.svg";
import background5 from "../assets/images/shared/home/reasons/5.svg";
import background6 from "../assets/images/shared/home/reasons/6.svg";
import background7 from "../assets/images/shared/home/reasons/7.svg";
import background8 from "../assets/images/shared/home/reasons/8.svg";
import background9 from "../assets/images/shared/home/reasons/9.svg";
import background10 from "../assets/images/shared/home/reasons/10.svg";
export const setsUsApartList = [
  {
    background: background1,
    label: "home.setUsApartCommunityCentricQualityOverQuantity",
  },
  {
    background: background2,
    label: "home.setUsApartTrustOverTransactionsResourcingApproach",
  },
  { background: background3, label: "home.setUsApartTalentEndorsedByExperts" },
  {
    background: background4,
    label: "home.setUsApartCommitmentToNoGhostingPolicy",
  },
  { background: background5, label: "home.setUsApartNoUseOfInternetJobBoards" },
  {
    background: background6,
    label: "home.setUsApartPersonalRespectAndTransparency",
  },
  {
    background: background7,
    label: "home.setUsApartProfitBackSatisfactionGuarantee",
  },
  {
    background: background8,
    label: "home.setUsApartProfitSharingWithCommunity",
  },
  {
    background: background9,
    label: "home.setUsApartEthicalAndKarmaInspiredBusinessPractices",
  },
  { background: background10, label: "home.setUsApartCostCompetitiveness" },
];
