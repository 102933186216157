import { MetaData } from "../interfaces/config/metaData";
const description =
  "Empowering organizations to seamlessly integrate strategy with execution, our innovative and transformative solutions address today's business complexities. Committed to collaboration and sustainable growth, we provide tailored guidance across industries, unlocking potential and driving meaningful impact.";
const metaData: MetaData = {
  home: {
    title: "Empowering Business Innovation | FlowForge Solutions Home",
    description,
  },
  contact: {
    title: "Get in Touch | FlowForge Solutions",
    description,
  },
  "our-story": {
    title: "Our Journey | The Foundation of FlowForge Solutions",
    description,
  },
  oasis: {
    title:
      "Welcome to Oasis | Your Gateway to Innovative Growth - FlowForge Solutions",
    description,
  },
  notFound: {
    title: "CanGuru | Not Found.",
    description,
  },
};

export default metaData;
