import React, { useEffect, useRef } from "react";
import { StrategyStepProps } from "../../interfaces/components/strategyStepProps";
import styles from "./strategyStep.module.scss";
import useDeviceType from "../../shared/hooks/deviceType";
import { DeviceTypes } from "../../shared/utils/enums";
import useIsSmallPhone from "../../shared/hooks/isSmallPhone";

/**
 * Component representing a step in the strategy.
 * @param {StrategyStepProps} props - The props for the component.
 * @returns {JSX.Element} - Rendered component.
 */
const StrategyStep: React.FC<StrategyStepProps> = ({
  icon,
  title,
  description,
  position,
  dotColor,
  index,
}: StrategyStepProps): JSX.Element => {
  const isSmall = useIsSmallPhone();
  /**
   * An object containing top spaces for different device types.
   * @type {{[key in DeviceTypes]: string[]}}
   */
  const topSpaces: {
    [DeviceTypes.tablet]: string[];
    [DeviceTypes.mobile]: string[];
  } = {
    [DeviceTypes.mobile]: [
      "-135px",
      "-200px",
      "-200px",
      "-286px",
      isSmall ? "-228px" : "-277px",
    ],
    [DeviceTypes.tablet]: ["-134px", "-200px", "-200px", "-286px", "-277px"],
  };
  /**
   * A reference to the step div element.
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const stepRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);
  /**
   * A hook to determine the current device type.
   * @type {DeviceTypes}
   */
  const deviceType: DeviceTypes = useDeviceType();

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current?.style.setProperty("--dot-color", dotColor);
    }
  }, [dotColor]);

  return (
    <div
      className={`relative ${styles.strategyStep} ${styles[position]}`}
      style={
        [DeviceTypes.mobile, DeviceTypes.tablet].includes(deviceType)
          ? {
              top: `${topSpaces[deviceType as keyof typeof topSpaces][index]}`,
            }
          : {}
      }
      ref={stepRef}
    >
      <div className={styles.stepContent}>
        <div className={"d-flex justify-content-center align-items-snm"}>
          <img
            className={`${styles.icon} ${title === "Ambitions" && styles["big-icon"]}`}
            width={"auto"}
            height={"auto"}
            src={icon}
            alt={title}
          />
        </div>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <div
        className={styles.timelineDot}
        style={{ backgroundColor: dotColor }}
      ></div>
    </div>
  );
};

export default StrategyStep;
