import { useMemo } from "react";
import useDeviceType from "./deviceType";
import { images } from "../../assets/images";

/**
 * Custom hook to select the appropriate image based on the device type.
 * @param {keyof typeof images} imageName - The key of the image in the images object.
 * @returns {string} The URL of the responsive image.
 */
const useResponsiveImage = (imageName: keyof typeof images): string => {
  /**
   * Get the current device type.
   */
  const deviceType = useDeviceType();

  /**
   * Memoized function to compute the responsive image URL based on the device type.
   */
  return useMemo((): string => {
    // Define the base path for images
    const mainImage = images[imageName];
    return mainImage[deviceType] || mainImage["desktop"];
  }, [deviceType, imageName]);
};

export default useResponsiveImage;
